@import '../theme-bootstrap';

.elc_dynamic_content_block {
  &__inner {
    position: relative;
  }
  &-two-columns {
    .elc_dynamic_content_block__inner {
      padding-top: 16px;
      @include container-padding-rules;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.31rem;
      @media #{$cr19-large-up} {
        display: flex;
        padding: 0;
      }
      .page-product & {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
    &.reverse-image-order {
      .elc_dynamic_content_block__inner {
        display: flex;
        flex-direction: row-reverse;
        .elc_dynamic_content_block__picture,
        .elc_dynamic_content_block--mobile {
          flex: 1;
        }
      }
    }
  }
  &__links {
    margin-top: 2.375rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    @media #{$cr19-large-up} {
      margin-top: 1.25rem;
      text-align: $ldirection;
      margin-bottom: 1.25rem;
    }
    .elc_dynamic_content_block-two-columns & {
      @include container-padding-rules;
      @media #{$cr19-large-up} {
        padding: 0;
      }
    }
    &.bottom-mb-ctas {
      @media #{$cr19-large-up} {
        display: none;
      }
    }
    &:empty {
      display: none;
    }
  }
  &__content-text {
    @include container-padding-rules;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.875rem;
    @media #{$cr19-large-up} {
      gap: 1.25rem;
      padding: 0;
    }
    &.top-mb-split-content {
      @media #{$cr19-large-up} {
        display: none;
      }
    }
    .page-product & {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
    }
  }
  &__content-items {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media #{$cr19-large-up} {
      margin-top: 1.3rem;
      flex-direction: row;
      gap: 1.5rem;
    }
    .elc_dynamic_content_block-two-columns & {
      margin: 0;
    }
    &.grid-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 85%;
      gap: 0;
      @media #{$cr19-large-up} {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
      }
      .elc_dynamic_content_block__content-items-wrapper {
        border-top: 1px solid $color-cl-light-black;
        margin: 0;
        &:nth-child(odd) {
          .elc_dynamic_content_block__content-items-inner {
            border-#{$rdirection}: 1px solid $color-cl-light-black;
            padding-#{$rdirection}: 0.69rem;
          }
        }
        &:nth-child(even) {
          .elc_dynamic_content_block__content-items-inner {
            padding-#{$ldirection}: 0.69rem;
          }
        }
        &:nth-child(1),
        &:nth-child(2) {
          border-top: none;
          .elc_dynamic_content_block__content-items-inner {
            margin-top: 0;
          }
        }
        &:nth-last-child(1),
        &:nth-last-child(2) {
          .elc_dynamic_content_block__content-items-inner {
            margin-bottom: 0;
          }
        }
        .elc_dynamic_content_block__content-items-inner {
          margin: 0.62rem 0;
        }
      }
    }
    &-wrapper {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      text-align: $ldirection;
      @media #{$cr19-large-up} {
        max-width: 12.5rem;
        gap: 0.62rem;
      }
      &:only-child {
        @media #{$cr19-large-up} {
          max-width: none;
        }
      }
    }
    &.left-border {
      .elc_dynamic_content_block__content-items-wrapper {
        padding-#{$ldirection}: 1.62rem;
        border-#{$ldirection}: 1px solid $color-cl-light-black;
      }
    }
    &.disable_first_left_line {
      .elc_dynamic_content_block__content-items-wrapper:first-child {
        padding: 0;
        border: none;
      }
    }
  }
  &__disclaimer {
    text-align: $ldirection;
    margin-top: 1rem;
    @media #{$cr19-large-up} {
      margin-top: 1.25rem;
    }
    p {
      margin: 0;
    }
  }
  &__second-container {
    @include container-padding-rules;
    @media #{$cr19-large-up} {
      padding: 0;
    }
    .elc_dynamic_content_block-two-columns & {
      padding: 0;
    }
    .page-product & {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
    }
  }
  &--pc {
    display: contents;
    & > .elc_dynamic_content_block__content {
      @include container-padding-rules;
      display: none;
      @media #{$medium-up} {
        padding-top: 4rem;
        padding-bottom: 5rem;
      }
      @media #{$cr19-large-up} {
        display: flex;
        position: absolute;
        top: 0;
        #{$ldirection}: auto;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
    }
  }
  &--mobile {
    display: block;
    @media #{$cr19-large-up} {
      display: none;
    }
    &.left-border-mb {
      .elc_dynamic_content_block__content-items-wrapper {
        padding-#{$ldirection}: 0.75rem;
        border-#{$ldirection}: 1px solid $color-cl-light-black;
      }
    }
  }
}

.editorial-grid-formatter__item {
  .block-template-elc-dynamic-content-block-v1 {
    margin: 0 -10px;
    @media #{$cr19-large-up} {
      margin: 0;
    }
  }
}
